import axios from 'axios'

const host = "https://na.winglobalpay.com"
const mchtId = "ais001"
const apiKey = "pk_dd7c-207f77-9b8-68b93"

export function vactFcs(params) {
    const headers = {
        Authorization: apiKey
    }

    const body = {
        "vact":{
            "mchtId": mchtId,
            "trackId": "",
            "bankCd": params.authBankCd,
            "account": params.authAccount,
            "payerName": params.payerName,
            "payerTel": params.payerTel,
            "dob": params.payerBirth,
            "gender": params.gender,
            "recvBankCd": "039",
            "itndAmount": params.itndAmount,
            "holderName": ""
        }
    }

    const request = host + "/api/v1/vactFcs"
    return axios.post(request, body, {headers: headers})
}