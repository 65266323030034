import axios from 'axios'
export const host = "https://web3pays.net/api/api/v1"
// export const host = "http://localhost:8083/api/v1"

function post(request, body) {
    return axios.post(request, body);
}

function get(request, body) {
    return axios.get(request, {
        params: body
    });
}

function put(request, body) {
    return axios.put(request, body);
}

function patch(request, body) {
    return axios.patch(request, body);
}

export function loginAccept(accountId) {
    const request = host + '/login/check/' + accountId
    return get(request)
}

export function getUserCard(params) {
    const request = host + "/user"
    return post(request, params)
}

export function putUserCard(body) {
    const request = host + "/user"
    return put(request, body)
}

export function putDeposit(body) {
    const request = host + "/deposit"
    return put(request, body)
}

export function getDepositList(body) {
    const request = host + "/deposit/list"
    return post(request, body)
}

export function putWithdraw(body) {
    const request = host + "/withdraw"
    return put(request, body)
}

export function getBankCode() {
    const request = host + "/common/bank-code"
    return get(request)
}

export function getUserCardHistory(body) {
    const request = host + "/settle/user/history"
    return post(request, body)
}

export function getUserCardApprHistory(body) {
    const request = host + "/pta/card/appr/list"
    return post(request, body)
}