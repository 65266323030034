import logo from '../assets/svg/logo.svg'
import logo2 from '../assets/svg/logo2.svg'
import mklogo from '../assets/svg/mk_logo.png'

// ### web3pays
export const cmpId = 5
export const message1 = "Web 3.0 기반 통합 포인트"
export const message2 = "장애인 후원 포인트 주관사"
export const imgLogo = (
    <div className={"flex flex-col gap-4 items-center"}>
        <img className={"w-32"} src={logo} alt={""}/>
        <img src={logo2} alt={""}/>
    </div>
)
export const foreColor1 = "#FF8B57"
export const foreColor2 = "#E94738"

// ### MK
// export const cmpId = 1;
// export const message1 = "민경산업 선진화 시스템에 오신 것을 환영합니다."
// export const message2 = "민경산업 법인카드 신청페이지입니다."
// export const imgLogo = <img src={mklogo} alt={""}/>
// export const foreColor1 = "#333333"
// export const foreColor2 = "#999999"